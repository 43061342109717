<template>
  <li
    class="user-list-item"
    :class="{
      active: this.data?.openedChat?.id_api === chat?.id_api,
    }"
    :id="chat.id_api"
    @touchstart="touchStartMethod"
    @touchend="touchEndMethod"
  >
    <div class="onlyForClicks" @click="setMessages(chat)"></div>
    <div class="btn-actions btn-left-actions" :id="'actionLeft-' + chat.id_api">
      <div class="row">
        <div class="col-6" @click="unreadChat(chat)">
          <i class="fas fa-eye-slash"></i> Não Lido
        </div>
        <div
          class="col-6"
          v-if="data.hasPermission('transfer-chats')"
          data-bs-toggle="modal"
          data-bs-target="#transfer-chat"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="setChatTransfer(chat)"
        >
          <i class="fas fa-share"></i> Transferir
        </div>
      </div>
    </div>
    <div
      @click="setMessages(chat)"
      class="avatar"
      v-bind:class="{
        'avatar-online': chat.isOnline == true && !chatIsGroup,
        'avatar-telegram':
          chat.contact_type === 'telegram_bot' &&
          this.data.user.showIcons == true,
        'avatar-messenger':
          chat.contact_type === 'messenger' && this.data.user.showIcons == true,
        'avatar-instagram':
          chat.contact_type === 'instagram' && this.data.user.showIcons == true,
        'avatar-twitter':
          chat.contact_type === 'twitter' && this.data.user.showIcons == true,
        'avatar-whatsapp':
          chat.contact_type === 'whatsapp' && this.data.user.showIcons == true,
      }"
    >
      <img
        @click="setMessages(chat)"
        v-lazy="{
          src: !chat.profilePicUrl ? defaultPic : chat.profilePicUrl,
          error: defaultPic,
        }"
        class="rounded-circle"
        alt="image"
      />
    </div>
    <div class="users-list-body">
      <div @click="setMessages(chat)">
        <h5>{{ chat.name }}</h5>
        <input
          type="hidden"
          v-on:change="changeContent()"
          :id="'chatType-' + chat.id_api"
          :value="chat.type"
        />
        <p v-if="chat.isComposing">
          <span class="animate-typing-col">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </span>
        </p>
        <p
          v-else-if="chat.isRecording"
          style="font-style: italic; color: #07e024"
        >
          <span class="material-icons">settings_voice</span> Gravando áudio
          <span class="animate-typing-col">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </span>
        </p>
        <p
          v-else-if="chat.isOpen != 0"
          :id="'content-' + chat.id_api"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          class="col-12 text-truncate"
          :title="getLastMsgText.text"
          v-html="getLastMsgText.html"
        ></p>
        <p
          v-if="chat.isOpen == 0"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
        >
          {{
            chat.id_api.replace(
              /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s.whatsapp\.net)/,
              "($2) $3-$4"
            )
          }}
        </p>
        <p style="display: none">
          {{ chat.id_api.replace("@s.whatsapp.net", "") }}
        </p>
      </div>
      <div class="last-chat-time">
        <a
          href="javascript:void(0);"
          data-bs-toggle="dropdown"
          class="contact-toggle d-none d-md-block"
        >
          <i class="fas fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <template v-if="!chatIsGroup">
            <a
              v-if="
                data?.config?.AccountConfig?.finishSendMsg &&
                data.hasPermission('finish-chats')
              "
              class="dropdown-item"
              v-on:click="finishChat(chat, true)"
              >Finalizar <span class="material-icons">archive</span></a
            >
            <a
              v-else-if="data.hasPermission('finish-chats')"
              class="dropdown-item"
              v-on:click="finishChat(chat, false)"
              >Finalizar <span class="material-icons">archive</span></a
            >
            <a
              v-if="
                data?.config?.AccountConfig?.finishSendMsg &&
                data.hasPermission('finish-chats')
              "
              class="dropdown-item"
              v-on:click="finishChat(chat, false)"
              >Finalizar sem mensagem
              <span class="material-icons">archive</span></a
            >
            <a
              v-if="data.hasPermission('transfer-chats')"
              v-on:click="setChatTransfer(chat)"
              class="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#transfer-chat"
              data-bs-dismiss="modal"
              aria-label="Close"
              >Transferir <span class="material-icons">autorenew</span></a
            >
          </template>
          <a class="dropdown-item" v-on:click="unreadChat(chat)"
            >Não lido <span class="material-icons">input</span></a
          >
        </div>
        <small class="text-muted" :id="'chatTime-' + chat.id_api"></small>
        <div class="new-message-count" v-if="chat.unreadCount">
          {{ chat.unreadCount }}
        </div>
      </div>
    </div>
    <div
      class="color-tag"
      v-for="lbl in getLabels()"
      v-bind:key="lbl.id"
      @click="setMessages(chat)"
    >
      <div class="tag" :style="'background-color:' + lbl.color"></div>
    </div>
    <div
      class="transfer-badge"
      v-if="chat.isTransfered"
      @click="setMessages(chat)"
    >
      <span class="text-transfer" @click="setMessages(chat)"
        >Chat transferido por
        {{
          chat.transferedById === -1
            ? "Sistema"
            : data.getUserById(chat.transferedById)?.name
        }}</span
      >
    </div>
    <div
      class="btn-actions btn-right-actions"
      :id="'actionRight-' + chat.id_api"
    >
      <div class="row">
        <div class="col-6" v-on:click="finishChat(chat, false)">
          <i class="fas fa-folder"></i> Finalizar
        </div>
        <div class="col-6" v-on:click="finishChat(chat, true)">
          <i class="fas fa-comment"></i> Finalizar
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { ChatCollection } from "../../stores/chatsStore";
import { api } from "../../service/api";
import {
  getTextOfMessageType,
  formatHtmlTextChat,
  getLastMessage,
} from "../../helpers/";

export default {
  props: ["chat"],
  setup() {
    const data = useStore();

    return {
      data,
      formatHtmlTextChat,
      defaultPic: userNoPhotoUrl,
      ChatCollection,
    };
  },
  async mounted() {
    if (window.location.href.includes("historico")) {
      await this.getUserChatting();
    }
  },
  data(instance) {
    return {
      chat_inst: instance.chat,
      baseURL: config.$api_url + "/",
      chatIdTransfer: "",
    };
  },
  methods: {
    hideActions(timeout) {
      setTimeout(() => {
        const leftActions = document.querySelectorAll(`.btn-left-actions`);
        const rightActions = document.querySelectorAll(`.btn-right-actions`);
        for (const i of leftActions) i.style.display = "none";
        for (const i of rightActions) i.style.display = "none";
      }, 300);
    },
    touchStartMethod(touchEvent) {
      //this.hideActions(0);
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXStart = touchEvent.changedTouches[0].clientX;
      addEventListener(
        "touchend",
        (touchEvent) => this.touchEndMethod(touchEvent, posXStart),
        { once: true }
      );
    },
    touchEndMethod(touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) {
        // We only care if one finger is used
        return;
      }
      const posXEnd = touchEvent.changedTouches[0].clientX;
      if (posXEnd - posXStart > 100) {
        const leftActions = document.getElementById(
          `actionLeft-${touchEvent.target.id.replace("content-", "")}`
        );
        if (leftActions) leftActions.style.display = "block";
      } else if (posXStart - posXEnd > 100) {
        const rightAction = document.getElementById(
          `actionRight-${touchEvent.target.id.replace("content-", "")}`
        );
        if (rightAction) rightAction.style.display = "block";
      }
    },
    async finishChat(chat, sendMessage) {
      this.hideActions();
      const chat2 = JSON.parse(JSON.stringify(chat));
      try {
        if (!chat2) return;
        this.data.editContact(chat2.id, {
          isOpen: 0,
          botId: 0,
          departmentId: 0,
          userIdOpened: 0,
          labels: null,
        });
        ChatCollection().destroy(chat2.id);
        this.$emit("closeWindowChat", chat2.id);
        api.post(`/chat/finish/${chat2.id_api}/${chat2.id}`, {
          channelType: chat2.contact_type,
          sendMessage: sendMessage === false ? false : true,
        });
      } catch (error) {
        console.error(error);
      }
    },
    unreadChat(chat) {
      this.hideActions();
      this.$emit("closeWindowChat", chat.id_api);
      //this.data.contacts.find((ctt) => ctt.id_api == chatId).unreadCount = 1;
      this.data.editContact(chat.id, { unreadCount: 1 });
      api.post(`/chat/unread/${chat.id_api}`);
    },
    getTimebyTimestamp() {
      const timestamp = this.chat.timestamp;
      const data = new Date(timestamp * 1000);
      data.toLocaleDateString();

      const element = document.getElementById("chatTime-" + this.chat.chatId);
      element.innerHTML = data.getHours() + ":" + data.getMinutes();
    },
    changeContent() {
      const type_content = this.chat.mimetype;
      let value = "";
      if (type_content == "image/jpeg") {
        value = "Foto ";
      } else {
        value = this.chat.content;
      }
      const element = document.getElementById("content-" + this.chat.chatId);
      element.innerHTML = value;
    },
    async setMessages(chat) {
      this.hideActions();
      const contato = await this.data.getContactById(chat.id);
      contato.Messages = contato.Messages.slice(-20);
      this.data.openedChat = contato;
      this.data.forward_msgIds = [];
      this.data.forward_chatsId = [];
      this.data.forward_openChat = true;
      this.data.editContact(chat.id, {
        unreadCount: 0,
      });

      if ($(window).width() < 992) {
        const chat = document.getElementsByClassName("chat-messages")[0];
        const leftSidebar = document.getElementsByClassName("left-sidebar")[0];
        const sidebarMenu = document.getElementsByClassName("sidebar-menu")[0];
        leftSidebar.style.display = "none";
        sidebarMenu.style.display = "none";
        chat.style.display = "block";
        chat.classList.add("show-chatbar");
      }
      document.getElementById("inputText")?.focus();

      const lastMsg = chat?.Messages[chat?.Messages?.length - 1];
      setTimeout(() => {
        const msg = document.getElementById(`content-${lastMsg?.msgId}`);
        msg?.scrollIntoView();
      }, 5);
      this.$router.push({
        query: {
          chat: chat.uuid,
        },
      });
      //setTimeout(() => {}, 2);
      //this.$emit("setMessages", chat);
    },
    setChatTransfer(chat) {
      this.hideActions();
      this.data.chatIdTransfer = chat;
    },
    getUserChatting() {
      const el = document.getElementById("chatting-" + this.chat_inst.id_api);
      const user = this.data.getUserById(this.chat_inst.userIdOpened);
      el.innerHTML = user
        ? (el.innerHTML = `Chat c/: ${user.name} ${user.surname || ""}`)
        : (el.innerHTML = "Sem atendente");
    },
    getLabels() {
      const labels = [];
      this.data.labels.map((lbl) => {
        if (this.chat_inst.labels) {
          if (parseInt(this.chat_inst.labels) == parseInt(lbl.id)) {
            labels.push(lbl);
          }
        }
      });
      return labels;
    },
  },
  computed: {
    getLastMsgText() {
      if (this.chat.Messages.length === 0) {
        return {
          text: "Sem mensagens carregadas...",
          html: "Sem mensagens carregadas...",
        };
      } else {
        return getTextOfMessageType(getLastMessage(this.chat));
      }
    },
    chatIsGroup() {
      return this.chat.id_api.includes("@g.us");
    },
  },
};
</script>
<style>
.transfer-badge {
  width: 100%;
  height: 15px;
  background-color: #ffb02e;
  position: absolute;
  left: 0;
  bottom: 0;
  align-items: center;
  text-align: center;
  line-height: 9pt;
}
.transfer-badge .text-transfer {
  font-size: 9pt;
  font-weight: 500;
  color: #303841;
}
.dropdown-menu {
  width: 250px;
}
.btn-left-actions,
.btn-right-actions {
  display: none;
}
.btn-actions {
  margin-left: -10px;
  margin-top: -18px;
  margin-bottom: -18px;
}
.btn-actions .row .col-6 i {
  display: block;
  font-size: 19pt;
  align-self: center;
  text-align: center;
}

.btn-actions .row .col-6 {
  height: 80px;
  text-align: center;
  vertical-align: center;
  padding-top: 18px;
}
.btn-left-actions .row .col-6:last-child {
  background-color: #7f65ec;
}
.btn-left-actions .row .col-6 {
  background-color: #a55233;
}
.btn-right-actions .row .col-6:last-child {
  background-color: #51a6fd;
}
.btn-right-actions .row .col-6 {
  background-color: #f15c6d;
}
</style>
