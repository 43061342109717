<script>
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { formatHtmlTextChat } from "../../helpers";
import SenderNameChatMessage from "../chats/Messages/SenderNameChatMessage.vue";

export default {
  props: ["reply", "chat"],
  setup() {
    return { UserStore: () => useStore(), formatHtmlTextChat };
  },
  data(instance) {
    return {
      reply_inst: instance.reply,
      baseURL: config.$api_url + "/",
    };
  },
  components: {
    SenderNameChatMessage,
  },
  methods: {
    setNullReply() {
      this.$emit("setNullReply", "");
    },
  },
  computed: {
    formatImageSrc() {
      if (this.reply.path.includes("base64")) {
        return this.reply.path;
      } else {
        return `${this.baseURL}static-files/public?id=${this.reply.path}&store=titanchat-uploads`;
      }
    },
    getImageCaption() {
      if (this.reply.caption) {
        const caption = this.reply.caption;
        return formatHtmlTextChat(caption);
      } else {
        return "Imagem";
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="message-reply" v-if="reply.type == 'chat'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div v-html="formatHtmlTextChat(reply.body)" class="content"></div>
    </div>
    <div class="message-reply" v-if="reply.type == 'ptt'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">🔊 Mensagem de voz</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'audio'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">🔊 Áudio</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'document'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">Documento</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'video'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">🔊 Vídeo</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'vcard'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">Contato</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'location'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">📌 Localização</div>
    </div>
    <div class="message-reply" v-if="reply.type == 'image'">
      <a href="javascript:void(0);" class="close_profile close_profile4">
        <span class="material-icons" @click="setNullReply">close</span>
      </a>
      <SenderNameChatMessage :message="reply" :chat="chat" />
      <div class="content">📷 {{ getImageCaption }}</div>
      <img :src="formatImageSrc" class="img" />
    </div>
  </div>
</template>
