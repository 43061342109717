// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.transfer-badge {
  width: 100%;
  height: 15px;
  background-color: #ffb02e;
  position: absolute;
  left: 0;
  bottom: 0;
  align-items: center;
  text-align: center;
  line-height: 9pt;
}
.transfer-badge .text-transfer {
  font-size: 9pt;
  font-weight: 500;
  color: #303841;
}
.dropdown-menu {
  width: 250px;
}
.btn-left-actions,
.btn-right-actions {
  display: none;
}
.btn-actions {
  margin-left: -10px;
  margin-top: -18px;
  margin-bottom: -18px;
}
.btn-actions .row .col-6 i {
  display: block;
  font-size: 19pt;
  align-self: center;
  text-align: center;
}
.btn-actions .row .col-6 {
  height: 80px;
  text-align: center;
  vertical-align: center;
  padding-top: 18px;
}
.btn-left-actions .row .col-6:last-child {
  background-color: #7f65ec;
}
.btn-left-actions .row .col-6 {
  background-color: #a55233;
}
.btn-right-actions .row .col-6:last-child {
  background-color: #51a6fd;
}
.btn-right-actions .row .col-6 {
  background-color: #f15c6d;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
