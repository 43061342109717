<template>
  <li class="user-list-item" style="opacity: 0.3">
    <div class="avatar placeholder-glow">
      <svg
        class="avatar"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        preserveAspectRatio="xMidYMid slice"
      >
        <rect width="100%" height="100%" fill="#868e96"></rect>
      </svg>
    </div>
    <div class="users-list-body">
      <div>
        <h5 class="placeholder-glow">
          <span class="placeholder col-6"></span>
        </h5>
        <p
          :id="'content-'"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          class="col-12 text-truncate placeholder-glow"
        >
          <span class="placeholder col-10"></span>
        </p>
      </div>
    </div>
  </li>
</template>
<script>
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";

export default {
  setup() {
    const data = useStore();

    return { data };
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      chatIdTransfer: "",
    };
  },
};
</script>
