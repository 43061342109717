<template>
  <transition appear name="slide-fade">
    <ul class="user-list mt-2 syncAlert" v-if="UserStore().isSyncing">
      Sincronizando seu painel...
      <br />
      <div class="progress" style="height: 4px">
        <div
          class="progress-bar bg-success progress-bar-striped"
          role="progressbar"
          :style="'width:' + UserStore().percentSyncing + '%'"
          :aria-valuenow="UserStore().percentSyncing"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <small
        v-show="UserStore().processedEventsSync < UserStore().countEventsSync"
        >Sincronizado {{ UserStore().processedEventsSync }}/{{
          UserStore().countEventsSync
        }}</small
      >
      <small
        v-show="UserStore().processedEventsSync >= UserStore().countEventsSync"
        >Concluído</small
      >
    </ul>
  </transition>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { ChatCollection } from "../../stores/chatsStore";
import { useStore } from "../../stores/dataStore";

export default defineComponent({
  setup() {
    return {
      ChatCollection,
      UserStore: () => useStore(),
    };
  },
});
</script>
